import CryptoJS from 'crypto-js/crypto-js'
import axios from 'axios';

// 1.准备参数: 平台分配的appId和appSecret
const appId = 'aib962c7c88e';
const appSecret = '8e181ffc85b9533f30576d839f6f7c0c8a85cf4f';
// 2.准备参数: 当前时间戳
const nonce = Date.now().toString();
// 3.拼接字符串
const text = appId + nonce + "aicity";
// 4.使用appSecret对字符串进行HmacSHA256加密, 将结果编码为hex格式
const signature = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(text, appSecret));


/** 登录 */
export default () => axios.post('https://lins-api.sensoro.com/auth/v2/session/developer/login', {
  appId, nonce, signature
});
