<template>
  <div class="dbs-page">
    <div
      class="dbs-page__inner"
      :style="{
        transform: `scale(${ratio}) translate(-50%, -50%)`,
      }">
      <header class="dbs-page__header">
        <h1 class="dbs-page__title">智慧樟村坪气象数据综合展示</h1>
        <span class="dbs-page__time">{{ time }}</span>
        <span class="dbs-page__date">{{ date }}</span>
      </header>

      <main class="dbs-page__main">
        <div class="dbs-page__weather">
          <div class="dbs-page__weather-item" v-for="e in list" :key="e.sn">
            <div class="dbs-page__weather-item__header">
              <span>{{ e.deployment.name }}</span>
              <span>实时气象</span>
            </div>
            <div class="dbs-page__weather-item__data-box">
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/温度.png"
                  alt="温度" />
                <div class="dbs-page__weather-item__data-item__title">温度</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.Temperature }} ℃
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/湿度.png"
                  alt="湿度" />
                <div class="dbs-page__weather-item__data-item__title">湿度</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.Humidity }} %
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/风速.png"
                  alt="风速" />
                <div class="dbs-page__weather-item__data-item__title">风速</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.WindSpeed_mps }} m/s
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/风力.png"
                  alt="风力" />
                <div class="dbs-page__weather-item__data-item__title">风力</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.WindGust_level }} 级
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/风向.png"
                  alt="风向" />
                <div class="dbs-page__weather-item__data-item__title">风向</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ WindDirections[e.meteorologyData.WindDirection] }}
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/气压.png"
                  alt="气压" />
                <div class="dbs-page__weather-item__data-item__title">气压</div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.Pressure }} hPa
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/雨.png"
                  alt="今日降雨" />
                <div class="dbs-page__weather-item__data-item__title">
                  今日降雨
                </div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.RainDailyNew_mm }} mm
                </div>
              </div>
              <div class="dbs-page__weather-item__data-item">
                <img
                  class="dbs-page__weather-item__data-item__img"
                  src="@/assets/雨.png"
                  alt="小时降雨" />
                <div class="dbs-page__weather-item__data-item__title">
                  小时降雨
                </div>
                <div class="dbs-page__weather-item__data-item__value">
                  {{ e.meteorologyData.RainRate_mm }} mm/h
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dbs-page__table-data">
          <div class="dbs-page__table-filter">
            <div class="dbs-page__form-item" style="flex: 0 0 auto">
              <div class="dbs-page__form-item__label">站点名称</div>
              <!-- <div class="dbs-page__select" style="width: 358px">
              <select name="">
                <option value="">全部</option>
                <option value="dog">Dog</option>
              </select>
            </div> -->
              <div style="width: 358px">
                <el-select
                  v-model="sn"
                  placeholder="请选择"
                  clearable
                  style="
                    width: calc(358px / 1.5);
                    transform: scale(1.5);
                    transform-origin: left;
                  "
                  @change="search">
                  <el-option
                    v-for="item in sensorDevicesOptions"
                    :key="item.sn"
                    :label="item.deployment.name"
                    :value="item.sn">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="dbs-page__form-item" style="flex: 1; display: flex">
              <div class="dbs-page__form-item__label">日期</div>
              <!-- <div
              class="dbs-page__datetime"
              style="flex: 1"
              @click="selectDateRange">
              <input
                type="text"
                name="daterange"
                value="01/01/2018 - 01/15/2018" />
            </div> -->
              <div style="flex: 1 1 733px">
                <el-date-picker
                  v-model="dateRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="
                    width: calc(733px / 1.5);
                    transform: scale(1.5);
                    transform-origin: left;
                  "
                  @change="search">
                </el-date-picker>
              </div>
            </div>

            <!-- <el-button type="primary" @click="search">搜索</el-button> -->
          </div>

          <div class="dbs-page__table-box">
            <table class="dbs-page__table">
              <thead>
                <tr>
                  <th style="width: 139px">
                    <div class="dbs-page__table__cell">站点名称</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">当日降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">最近1小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">前3小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">前6小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">前12小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">前24小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">前48小时降雨量(mm)</div>
                  </th>
                  <th>
                    <div class="dbs-page__table__cell">昨日降雨量(mm)</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in sensorDevices" :key="index">
                  <th>
                    <div class="dbs-page__table__cell">
                      {{ item.deployment.name }}
                    </div>
                  </th>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.dr }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h1 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h3 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h6 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h12 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h24 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.h48 }}</div>
                  </td>
                  <td>
                    <div class="dbs-page__table__cell">{{ item.zr }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  getMeteorologyList,
  getSensorDevices,
  getSensorDevicesDetails,
  getSensorDevicesHistory,
} from "@/api/api";
import dayjs from "dayjs";

function calcRatio() {
  let scaleRatio = 1;
  const oRatio = 1920 / 1080;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const nRatio = windowWidth / windowHeight;
  console.log(oRatio, nRatio);
  if (oRatio > nRatio) {
    console.log(windowWidth, 1920, windowWidth / 1920);
    scaleRatio = windowWidth / 1920;
  } else {
    scaleRatio = windowHeight / 1080;
  }

  console.log(scaleRatio);

  return scaleRatio;
}

function genItem(v) {
  const dr = v.appSensorData.find((v2) => v2.name == "当日降雨量");
  const xs = v.appSensorData.find((v2) => v2.name == "小时降雨量");
  const zr = v.appSensorData.find((v2) => v2.name == "昨日降雨量");
  return {
    ...v,
    dr: dr.value,
    xs: xs.value,
    zr: zr.value,
  };
}

async function getSensorDevicesHistoryNum(data, startTime, endTime) {
  const resp = await getSensorDevicesHistory(data.sn, {
    sort: "desc",
    page: 1,
    size: 1,
    startTime,
    endTime,
  });
  if (resp?.data?.code === 0) {
    const data = resp.data.data;
    return data.list;
  }
}

async function setH1(data, startTime, endTime) {
  const list = await getSensorDevicesHistoryNum(data, startTime, endTime);
  const h1 = list[0]?.sensorData.lastHourRainfall ?? 0;
  data.h1 = h1.toFixed(2);
}

async function setH48(data, startTime, endTime, hourDataList, index) {
  const list = await getSensorDevicesHistoryNum(data, startTime, endTime);

  hourDataList[index] = list[0]?.sensorData.lastHourRainfall ?? 0;
}

const orderList = [
  "秦家坪村",
  "梨耳坪村",
  "犁耳坪村",
  "殷家坪社区",
  "羊角山村",
  "云霄垭村",
  "栗林河村",
  "黄马河村",
  "三堡垭村",
  "古村村",
  "古村",
  "董家河村",
  "黄家台村",
  "桃坪河村",
  "砦沟村",
  "丁家河村",
  "樟村坪社区",
];

export default {
  data() {
    return {
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      date: "",
      time: "",
      WindDirections: [
        "无",
        "南",
        "西南",
        "西",
        "西北",
        "北",
        "东北",
        "东",
        "东南",
      ],
      list: [],
      dateRange: null,
      // sn: "21063859",
      sn: null,
      // 传感器设备列表
      sensorDevices: [],
      ratio: 1,
      // 传感器选项
      sensorDevicesOptions: [],
    };
  },
  created() {
    setInterval(() => {
      let d = new Date();
      this.date = `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()} 星期${
        this.weeks[d.getDay()]
      }`;
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");
      this.time = `${d.getHours()}:${minutes}:${seconds}`;
    }, 1000);
  },
  mounted() {
    this.getData();
    this.initSensorDevicesOptions();
    this.search();
    // 每1分钟请求一次数据
    setInterval(async () => {
      this.getData();
      this.initSensorDevicesOptions();
      this.search();
    }, 60 * 1000);

    $('input[name="daterange"]').daterangepicker(
      {
        opens: "left",
      },
      function (start, end, label) {
        console.log(
          "A new date selection was made: " +
            start.format("YYYY-MM-DD") +
            " to " +
            end.format("YYYY-MM-DD")
        );
      }
    );

    this.calcRatio();
  },
  methods: {
    search() {
      if (this.sn) {
        this.initSensorDevicesDetails();
      } else {
        this.initSensorDevices();
      }
    },
    calcRatio() {
      this.ratio = calcRatio();
    },
    selectDateRange() {},
    /** 获取气象站列表 */
    async getData() {
      let meteorologyList = await getMeteorologyList();
      if (meteorologyList?.data?.code === 0) {
        this.list = meteorologyList.data.data.list;
      }
    },
    async initSensorDevicesDetails() {
      let resp = await getSensorDevicesDetails(this.sn);
      if (resp?.data?.code === 0) {
        let data = resp.data.data;
        Object.assign(data, {
          dr: "0.00",
          zr: "0.00",
          h1: "0.00",
          h3: "0.00",
          h6: "0.00",
          h12: "0.00",
          h24: "0.00",
          h48: "0.00",
        });
        let date = dayjs().toDate();
        if (this.dateRange) {
          date = dayjs(this.dateRange[1]).toDate();
        }
        this.setData(data, date);
        this.sensorDevices = [data];
      }
    },
    // 初始化传感器选项
    async initSensorDevicesOptions() {
      const resp = await getSensorDevices({
        category: "RAIN_GAUGE",
      });
      if (resp?.data?.code === 0) {
        const list = resp.data.data.list.sort((a, b) => {
          const aOrder = orderList.findIndex((v) => v == a.deployment.name);
          const bOrder = orderList.findIndex((v) => v == b.deployment.name);
          return aOrder - bOrder;
        });
        this.sensorDevicesOptions = [
          {
            sn: null,
            deployment: {
              name: "全部",
            },
          },
          ...list,
        ];
      }
    },
    // 初始化传感器设备列表
    async initSensorDevices() {
      this.sensorDevices = [];
      const resp = await getSensorDevices({
        category: "RAIN_GAUGE",
      });
      if (resp?.data?.code === 0) {
        const sensorDevices = resp.data.data.list;

        const pList = [];
        for (const item of sensorDevices) {
          let date = dayjs().toDate();
          if (this.dateRange) {
            date = dayjs(this.dateRange[1]).toDate();
          }

          const startTime = dayjs(date).subtract(1, "h").valueOf();
          const endTime = dayjs(date).valueOf();
          pList.push(setH1(item, startTime, endTime));
        }

        await Promise.all(pList);

        this.sensorDevices = sensorDevices
          .sort((a, b) => {
            const bH1 = parseFloat(b.h1);
            const aH1 = parseFloat(a.h1);
            // console.log(bH1, aH1);
            if (bH1 == aH1) {
              const aOrder = orderList.findIndex((v) => v == a.deployment.name);
              const bOrder = orderList.findIndex((v) => v == b.deployment.name);
              return aOrder - bOrder;
            } else {
              return bH1 - aH1;
            }
          })
          .map((data) => {
            Object.assign(data, {
              dr: "0.00",
              zr: "0.00",
              h1: "0.00",
              h3: "0.00",
              h6: "0.00",
              h12: "0.00",
              h24: "0.00",
              h48: "0.00",
            });
            let date = dayjs().toDate();
            if (this.dateRange) {
              date = dayjs(this.dateRange[1]).toDate();
            }
            this.setData(data, date);
            return data;
          });
      }
    },
    async setData(data, date) {
      const startTime = dayjs(date).subtract(1, "h").valueOf();
      const endTime = dayjs(date).valueOf();
      const list = await getSensorDevicesHistoryNum(data, startTime, endTime);
      const data2 = list[0];
      data.dr = parseFloat(data2.sensorData.todayRainfall).toFixed(2);
      data.zr = parseFloat(data2.sensorData.yesterdayRainfall).toFixed(2);

      const totalHours = 48;
      const hourDataList = new Array(totalHours).fill(0);
      let time = dayjs(date);
      const pList = [];
      for (let i = 0; i < totalHours; i++) {
        const startTime = time.subtract(1, "h").valueOf();
        const endTime = time.valueOf();

        pList.push(setH48(data, startTime, endTime, hourDataList, i));
        // const list = await getSensorDevicesHistoryNum(data, startTime, endTime);
        // hourDataList.push(list[0]?.sensorData.hourRainfall ?? 0);

        time = time.subtract(1, "h");
      }

      await Promise.all(pList);

      const h1 = hourDataList[0];
      data.h1 = h1.toFixed(2);
      const h3 = hourDataList.slice(0, 3).reduce((a, b) => a + b, 0);
      data.h3 = h3.toFixed(2);
      const h6 = hourDataList.slice(0, 6).reduce((a, b) => a + b, 0);
      data.h6 = h6.toFixed(2);
      const h12 = hourDataList.slice(0, 12).reduce((a, b) => a + b, 0);
      data.h12 = h12.toFixed(2);
      const h24 = hourDataList.slice(0, 24).reduce((a, b) => a + b, 0);
      data.h24 = h24.toFixed(2);
      const h48 = hourDataList.slice(0, 48).reduce((a, b) => a + b, 0);
      data.h48 = h48.toFixed(2);
      console.log(data);
    },
  },
};
</script>

<style>
.daterangepicker {
  color: black;
}
</style>

<style lang="scss" scoped>
.dbs-page {
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/dbs-bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 24px;
    width: 1920px;
    height: 1080px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transform-origin: left top;
  }

  &__header {
    flex: 0 0 auto;
    margin-bottom: 36px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &__title {
    font-size: 78px;
    font-weight: 400;
    color: #003726;
    font-family: HYShuiDiJ;
    letter-spacing: 5px;
  }
  &__time {
    margin-left: 64px;
    font-family: HYXueJunJ;
    font-weight: 400;
    font-size: 59px;
    color: #ff7e00;
    font-style: italic;
  }
  &__date {
    margin-left: 37px;
    font-family: HYXueJunJ;
    font-weight: 400;
    font-size: 31px;
    color: #ff7e00;
    font-style: italic;
  }

  &__main {
    flex: 1;
    min-height: 0;
    display: flex;
    gap: 25px;
  }
  &__weather {
    flex: 0 0 auto;
    background: rgba(255, 255, 255, 0.59);
    border: 1px solid #9ccbb5;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__weather-item {
    &__header {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 36px;
      color: #000000;
      text-align: center;
      margin-bottom: 20px;
    }
    &__data-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
    &__data-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba($color: #208767, $alpha: 0.19);
      min-height: 159px;
      min-width: 87px;
      border-radius: 11px;

      &__img {
        margin-top: 22px;
        width: 44px;
        height: 44px;
        object-fit: contain;
      }
      &__title {
        margin-top: 12px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 18px;
        color: #000000;
      }
      &__value {
        margin-top: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 22px;
        color: #208767;
      }
    }
  }
  &__table-data {
    flex: 1;
    border: 1px solid #9ccbb5;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: rgba(255, 255, 255, 0.59);
  }
  &__table-filter {
    flex: 0 0 auto;
    margin-bottom: 27px;
    display: flex;
    gap: 64px;
  }
  &__table-box {
    flex: 1;
    border: 1px solid #9ccbb5;
    position: relative;
    // height: 700px;
    height: 0px;
    overflow: auto;
  }
  &__table {
    border-collapse: collapse;
    // background: rgba(255,255,255,0.5);
    background: rgba(255, 255, 255, 0.59);

    thead,
    tbody,
    tr,
    th,
    td {
      border: 1px solid #9ccbb5;
    }
    th,
    td {
    }
    thead {
      position: sticky;
      top: -1px;
      background: #cce6d1;
    }
    th {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 22px;
      color: #000000;
    }
    tr {
      table-layout: fixed;
    }
    &__cell {
      padding: 10px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      font-family: Microsoft YaHei;
      color: #208767;
    }
  }

  &__form-item {
    display: flex;
    align-items: center;
    &__label {
      color: #000000;
      font-size: 22px;
      font-weight: 400;
      font-family: Microsoft YaHei;
      margin-right: 15px;
    }
  }
  &__select {
    border: 1px solid #9ccbb5;
    padding: 0 20px;
    width: 200px;
    height: 55px;
    font-family: Microsoft YaHei;
    display: flex;
    align-items: center;
    select {
      border: none;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      font-weight: 400;
      font-size: 22px;
      color: #208767;
      appearance: none;
    }
    &:after {
      width: 30px;
      height: 30px;
      content: "";
      background: url("@/assets/下拉.png") no-repeat center;
      background-size: contain;
    }
  }
  &__datetime {
    border: 1px solid #9ccbb5;
    padding: 0 20px;
    width: 200px;
    height: 55px;
    font-family: Microsoft YaHei;
    display: flex;
    align-items: center;
    input {
      border: none;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      font-weight: 400;
      font-size: 22px;
      color: #208767;
      appearance: none;
    }
    &:after {
      width: 30px;
      height: 30px;
      content: "";
      background: url("@/assets/日期.png") no-repeat center;
      background-size: contain;
    }
  }
}
</style>
