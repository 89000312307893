<template>
  <homePage />
</template>

<script>
import homePage from '@/views/homePage.vue'
export default {
  components: { homePage },
}
</script>

<style lang="scss">
*{
  margin: 0;
}
body{
  background-color: #0028c2;
  color: #fff;
  // min-width: 1000px;
  // background-image: url(@/assets/bg.png);
  // background-repeat: no-repeat;
  // background-position: center 0;
}
</style>