import axios from 'axios';
import cookie from 'js-cookie';
import login from './login';


// 创建axios实例
const request = axios.create({
  baseURL: 'https://lins-api.sensoro.com',
  timeout: 30000, // 请求超时时间
})


// request拦截器
request.interceptors.request.use(
  async config => {
    let token = cookie.get('token');
    // console.log("token:"+token);
    let projectid = cookie.get('projectid');
    // console.log("projectid:"+projectid);
    // 没有token或projectid则登录获取
    if(!token || !projectid){
      let res = await login();
      if(res?.data?.code === 0){
        token = res.data.data.token
        projectid = res.data.data.project.id
        cookie.set('token', token);
        cookie.set('projectid', projectid);
      }else{
        alert('登录失败');
      }
    }

    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['x-lins-projectid'] = projectid;

    return config
  },
  error => {
      Promise.reject(error)
  }
)


/** 获取气象站列表 */
export const getMeteorologyList = () => request.get('/meteorology/v1/meteorology');

/** 获取气象站详情 */
export const getMeteorologyDetail = (sn) => request.get(`/meteorology/v1/meteorology/${sn}`);

/** 查看传感器列表 */
export const getSensorDevices = (data) => request.get(`/sensorDevice/v1/sensorDevices`, {
  params: data
});

/** 查看传感器详情 */
export const getSensorDevicesDetails = (sn) => request.get(`/sensorDevice/v1/sensorDevices/${sn}`);

/** 传感器历史数据查询 */
export const getSensorDevicesHistory = (sn, data) => request.get(`/sensorDevice/v1/data/list/${sn}`, {
  params: data
});
